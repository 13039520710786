<template>
  <div class="yx-wid yx-bg">
    <div class="yx-wid banner">
      <img
        v-lazy="item.imgUrl"
        v-for="(item, index) in banners"
        :key="index"
        @click="openBanner(item)"
        :class="{ hover: index === bannerInd }"
      />
      <!-- 快捷banner导航 -->
      <div class="banner-nav">
        <span
          v-for="(item, index) in banners"
          :key="index"
          :class="{ hover: index === bannerInd }"
          @click="tabBanner(item, index)"
        ></span>
      </div>
      <!-- 左右切换 -->
      <div class="banner-prev" @click="tabPrev"></div>
      <div class="banner-next" @click="tabNext"></div>

      <!-- 跑马灯 -->
      <div class="yx-wid home-ad">
        <div class="yx-wid-12">
          <div class="home-ad-icon"></div>
          <div class="home-ad-scroll" v-if="newsList" ref="scorll">
            <div
              class="home-ad-text"
              @mouseout="scrollStar"
              @mouseover="moveScroll"
              ref="scorllWid"
              :style="{
                transform: 'translateX(' + scorllLeftVal + 'px)',
                transition: scrollLeftStar,
              }"
            >
              <div
                class="row"
                v-for="(item, index) in newsList"
                @click="openMessage(item)"
                :key="index"
                v-html="item.scrollContent"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- APP下载 -->
    <div class="yx-wid-12 yx-load wow animate__animated animate__fadeInUp">
      <div class="yx-load-left">
        <div
          class="yx-load-box"
          v-for="(item, index) in loads"
          :key="index"
          :class="item.class"
        >
          <img
            :src="row"
            v-for="(row, rowIn) in item.list"
            class="img"
            :style="{
              'animation-delay': '0.' + rowIn + 's',
              marginLeft: rowIn === 2 && loadInd === 1 ? '120px' : '',
              height: rowIn === 2 && loadInd === 1 ? '94%' : '',
            }"
          />
          <img
            src="@/assets/images/load/bg.webp"
            class="bg"
            :style="{ left: loadInd === 1 ? '20px' : '' }"
          />
        </div>
      </div>
      <div class="yx-load-right">
        <h3>极致流畅 触手可及</h3>
        <div class="yx-load-menus">
          <ul>
            <li
              v-for="(item, index) in loads"
              @click="loadTab(item, index)"
              :class="{ hover: loadInd === index }"
              :key="index"
            >
              {{ item.name }}
            </li>
          </ul>
          <div
            :style="{ transform: 'translateX(' + 140 * loadInd + 'px)' }"
            class="yx-load-menus-bg btn-3d-blue"
          ></div>
        </div>
        <div class="yx-load-main">
          <p>{{ loads[loadInd].sec }}</p>
          <div class="yx-load-row">
            <div class="yx-load-item">
              <div class="code" v-if="baseInfo && baseInfo[22]">
                <QRCode
                  v-if="baseInfo && baseInfo[22].configValue"
                  :text="baseText('iosApp')"
                  v-show="loadInd === 0"
                  :width="120"
                  :height="120"
                />
                <QRCode
                  v-if="baseInfo && baseInfo[22].configValue"
                  :text="baseText('androidApp')"
                  v-show="loadInd === 1"
                  :width="120"
                  :height="120"
                />
              </div>
              <p>
                扫码下载APP<br />
                {{ loads[loadInd].name }}
              </p>
            </div>
            <div class="yx-load-item">
              <div class="img">
                <img src="@/assets/images/load/h5.webp" alt="" />
              </div>
              <p v-if="baseInfo && baseInfo[22]">
                无需下载直接访问<br />
                <a :href="baseText(loads[loadInd].code)" class="text-blue">{{
                  baseText(loads[loadInd].code)
                }}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 热门游戏 -->
    <section
      class="yx-wid-12 ab-hotgame wow animate__animated animate__fadeInUp"
    >
      <div class="ab-title">包容万象 从容选择</div>
      <div
        class="ab-hotgame-box"
        v-if="hotsGameList && hotsGameList[hotsGameInd]"
      >
        <ul>
          <li>
            <section class="ab-hotgame-title" :class="hotsMove">
              <img
                v-if="hotsGameList[hotsGameInd].layout"
                :style="{ 'animation-delay': '0.1s' }"
                :src="
                  require('@/assets/images/home/' +
                    hotsGameList[hotsGameInd].layout +
                    '/title.webp')
                "
              />
              <p :style="{ 'animation-delay': '0.2s' }">
                {{ hotsGameList[hotsGameInd].gameDescription }}
              </p>
            </section>
            <section
              class="ab-hotgame-main"
              :class="[hotsGameList[hotsGameInd].layout, hotsMove]"
            >
              <template v-if="hotsGameList[hotsGameInd].layout === 'realbet'">
                <img class="one" src="@/assets/images/home/realbet/01.webp" />
                <img
                  class="two upMode"
                  src="@/assets/images/home/realbet/02.webp"
                />
                <img
                  class="three downMode"
                  src="@/assets/images/home/realbet/03.webp"
                />
              </template>
              <template v-if="hotsGameList[hotsGameInd].layout === 'egame'">
                <img class="one" src="@/assets/images/home/egame/01.webp" />
                <img
                  class="two upMode"
                  src="@/assets/images/home/egame/02.webp"
                />
                <img class="bg" src="@/assets/images/home/egame/bg.webp" />
              </template>
              <template v-if="hotsGameList[hotsGameInd].layout === 'lottery'">
                <img class="one" src="@/assets/images/home/lottery/01.webp" />
                <img class="two" src="@/assets/images/home/lottery/02.webp" />
                <img class="three" src="@/assets/images/home/lottery/03.webp" />
                <img class="four" src="@/assets/images/home/lottery/04.webp" />
                <img
                  class="five upMode"
                  src="@/assets/images/home/lottery/05.webp"
                />
                <img class="six" src="@/assets/images/home/lottery/06.webp" />
                <div class="seven downMode">
                  <img src="@/assets/images/home/lottery/07.webp" />
                  <img
                    src="@/assets/images/home/lottery/07-1.webp"
                    class="show"
                  />
                </div>
                <div class="eigth upMode" style="animation-delay: 1s">
                  <img src="@/assets/images/home/lottery/08.webp" />
                  <img
                    src="@/assets/images/home/lottery/08-1.webp"
                    class="show"
                  />
                </div>
              </template>
              <template v-if="hotsGameList[hotsGameInd].layout === 'sport'">
                <img class="one" src="@/assets/images/home/sport/01.webp" />
                <img class="two" src="@/assets/images/home/sport/02.webp" />
                <img class="three" src="@/assets/images/home/sport/03.webp" />
                <img class="bg" src="@/assets/images/home/sport/bg.webp" />
                <img class="four" src="@/assets/images/home/sport/04.webp" />
                <img class="five" src="@/assets/images/home/sport/05.webp" />
                <img
                  class="six upMode"
                  src="@/assets/images/home/sport/06.webp"
                />
                <img
                  class="seven upMode"
                  src="@/assets/images/home/sport/07.webp"
                />
              </template>
              <template v-if="hotsGameList[hotsGameInd].layout === 'gaming'">
                <img class="one" src="@/assets/images/home/gaming/01.webp" />
                <img
                  class="two upMode"
                  src="@/assets/images/home/gaming/02.webp"
                />
                <img
                  class="three downMode"
                  src="@/assets/images/home/gaming/03.webp"
                />
              </template>

              <template v-if="hotsGameList[hotsGameInd].layout === 'fishing'">
                <img class="one" src="@/assets/images/home/fishing/01.webp" />
                <img
                  class="two upMode"
                  src="@/assets/images/home/fishing/02.webp"
                />
                <img
                  class="three downMode"
                  src="@/assets/images/home/fishing/03.webp"
                />
                <img class="four" src="@/assets/images/home/fishing/04.webp" />
                <img class="bg" src="@/assets/images/home/fishing/bg.webp" />
              </template>
              <template v-if="hotsGameList[hotsGameInd].layout === 'chess'">
                <img class="one" src="@/assets/images/home/chess/01.webp" />
                <img
                  class="two upMode"
                  src="@/assets/images/home/chess/02.webp"
                />
                <img
                  class="three downMode"
                  src="@/assets/images/home/chess/03.webp"
                />
                <img
                  class="four downMode"
                  src="@/assets/images/home/chess/04.webp"
                />
                <img class="bg" src="@/assets/images/home/chess/bg.webp" />
              </template>
            </section>
            <section
              class="ab-hotgame-menus"
              v-if="hotsGameList[hotsGameInd].layout"
            >
              <dl :class="hotsMove">
                <dd
                  v-for="(item, index) in hotsGameList[hotsGameInd].list"
                  :key="index"
                  v-if="index < 3"
                  :style="{ 'animation-delay': '0.' + index + 's' }"
                  @click="openGame(item, index)"
                >
                  <img
                    :src="
                      require('@/assets/images/home/' +
                        hotsGameList[hotsGameInd].layout +
                        '/menus0' +
                        (index + 1) +
                        '.webp')
                    "
                    class="icon"
                  />
                  <template
                    v-if="
                      hotsGameList[hotsGameInd].list.length > 3 && index === 2
                    "
                  >
                    <strong>更多场馆</strong>
                    <p>MORE VENUES</p>
                  </template>
                  <template v-else>
                    <strong>{{ item.apiTitle }}</strong>
                    <p>{{ item.apiTitleEn }}</p>
                  </template>
                </dd>
              </dl>
            </section>
          </li>
        </ul>
      </div>
      <div
        class="ab-hotgame-navs yx-load-menus"
        v-if="hotsGameList && hotsGameList[hotsGameInd].list"
      >
        <ul>
          <li
            v-for="(item, index) in hotsGameList"
            :key="index"
            @click="TabHotsGame(item, index)"
            :class="{ hover: hotsGameInd === index }"
          >
            {{ item.gameTypeName }}
          </li>
        </ul>
        <div
          :style="{ transform: 'translateX(' + 140 * hotsGameInd + 'px)' }"
          class="yx-load-menus-bg btn-3d-blue"
        ></div>
      </div>
    </section>
    <!-- 优质服务 -->
    <section
      class="yx-wid-12 ab-hotgame wow animate__animated animate__fadeInUp"
    >
      <div class="ab-title">优质服务 安全稳定</div>
      <div class="ab-service">
        <ul>
          <li v-for="(item, index) in services" :key="index">
            <div class="load">
              <div class="time">
                <span class="text-ak">{{ item.time }}</span>
                <p>{{ item.unit }}</p>
              </div>
              <img :src="item.loading" class="gif" />
            </div>
            <div class="name">{{ item.name }}</div>
          </li>
        </ul>
        <dl>
          <dd v-for="(item, index) in serviceList" :key="index">
            <div class="icon">
              <img :src="item.icon" />
            </div>
            <span class="h3">{{ item.name }}</span>
            <p class="sec">{{ item.sec }}</p>
          </dd>
        </dl>
      </div>
    </section>

    <!-- 合作伙伴 -->
    <section
      class="yx-wid-12 ab-hotgame wow animate__animated animate__fadeInUp"
    >
      <div class="ab-title">合作伙伴</div>
      <div class="ab-parther" @click="$router.push('/sponsor/madrid')">
        <img
          src="@/assets/images/home/partner01.webp"
          class="ab-parther-left"
        />
        <img
          src="@/assets/images/home/partner02.webp"
          class="ab-parther-right"
        />
      </div>
    </section>
    <!-- 公告 -->
    <Bulletin
      v-if="ismsgShow && newsList"
      :visible.sync="ismsgShow"
      :listData="newsList"
    />
  </div>
</template>
<script>
import { WOW } from "wowjs";
import QRCode from "@/components/QRCode.vue";
import Bulletin from "@/components/Bulletin.vue";
import { mapState } from "vuex";
export default {
  components: { QRCode, Bulletin },
  name: "Home",
  data() {
    return {
      ismsgShow: false,
      services: [
        {
          name: "平局存款时间",
          time: 60,
          unit: "秒",
          loading: require("@/assets/images/home/bggif1.gif"),
        },
        {
          name: "平局取款时间",
          time: 90,
          unit: "秒",
          loading: require("@/assets/images/home/bggif2.gif"),
        },
        {
          name: "合作支付平台",
          time: 80,
          unit: "家",
          loading: require("@/assets/images/home/bggif3.gif"),
        },
        {
          name: "合作游戏平台",
          time: 24,
          unit: "家",
          loading: require("@/assets/images/home/bggif4.gif"),
        },
      ],
      serviceList: [
        {
          icon: require("@/assets/images/home/icon01.webp"),
          name: "更专业",
          sec: "每天为您提供近千场精彩体育、电竞赛事，玩法多元极致享受，更有真人、棋牌、彩票、电子游戏等多种娱乐方式随心选择。",
        },
        {
          icon: require("@/assets/images/home/icon02.webp"),
          name: "更安全",
          sec: "独家开发，采用128位加密技术和严格的安全管理体系，客户资金得到最完善的保障，让您全情尽享娱乐、赛事投注，无后顾之忧！",
        },
        {
          icon: require("@/assets/images/home/icon03.webp"),
          name: "更便捷",
          sec: "自主研发的 Web、H5，更有iOS、Android原生App，让您随时随地，娱乐投注随心所欲！7×24小时在线客服提供最贴心、最优质的服务。",
        },
        {
          icon: require("@/assets/images/home/icon04.webp"),
          name: "更快速",
          sec: "最新技术自主研发的财务处理系统，真正做到极速存、取、转。独家网络优化技术，为您提供一流的游戏体验，最大优化网络延迟。",
        },
      ],
      bannerInd: 0,
      bannerTimer: null,
      loads: [
        {
          name: "IOS APP",
          title: "iOS APP",
          code: "iosApp",
          type: 1,
          sec: "全球首家一体化娱乐原生APP ，尽显流畅，完美操作。海量体育，电竞顶尖赛事，真人娱乐，彩票投注及电子游艺等，最新最全娱乐项目尽在掌中体验扫码下载，即刻拥有！",
          list: [
            require("@/assets/images/load/ios01.png"),
            require("@/assets/images/load/ios02.png"),
            require("@/assets/images/load/ios03.webp"),
          ],
          link: "",
          class: "move",
        },
        {
          name: "Andriod APP",
          title: "andriod APP",
          code: "androidApp",
          type: 2,
          sec: "业内赔率最高！覆盖世界各地赛事，让球、大小、半全场、波胆、单双、总入球、连串过关等多元竞猜。更有动画直播，让您体验轻松聊球，娱乐投注两不误。",

          list: [
            require("@/assets/images/load/android01.webp"),
            require("@/assets/images/load/android02-1.webp"),
            require("@/assets/images/load/android02.png"),
            require("@/assets/images/load/android03.webp"),
            require("@/assets/images/load/android04.webp"),
          ],
          link: "",
          class: "",
        },
      ],
      loadInd: 0,
      liveTabs: [
        { name: "真人主播", type: "" },
        { name: "足球赛事", type: "" },
        { name: "篮球赛事", type: "" },
        { name: "电竞赛事", type: "" },
      ],
      liveInd: 0,
      hotsGameInd: 0,
      newsList: null,
      banners: null,
      canvas: null,
      context: null,
      flash: 0.1,
      scorllLeftVal: 0,
      scrollLeftStar: "all .4s",
      timerText: null,
      scorllWid: 0,
      isScroll: false,
      loadTargetName: "iosApp",
      loadTargetStyle: "",
      hotsMove: "",
      hotsMoveXh: "",
    };
  },
  computed: {
    ...mapState(["navsVal", "navsList", "isLogin", "baseInfo", "hotsGameList"]),
  },
  watch: {
    navsVal(val) {
      if (!val) return;
      if (val) {
        this.initGameList();
      }
    },
    baseInfo(val) {
      if (val && val.length) {
        this.initBaseInfo();
      }
    },
  },
  mounted() {
    // this.autoBanner();
    this.getTop50MessageList();
    this.getSilde();
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: true,
    });
    wow.init();
  },
  methods: {
    // 基础信息赋值给data上
    initBaseInfo() {
      if (!this.baseInfo) return;
      this.loads.forEach((item) => {
        if (item.type === 1) {
          item.link = this.baseInfo.find(
            (item) => item.configKey === "androidApp"
          ).configValue;
        }
        if (item.type === 2) {
          item.link = this.baseInfo.find(
            (item) => item.configKey === "iosApp"
          ).configValue;
        }
      });
    },
    initGameList() {
      const arr = this.navsList.filter(
        (item) => !["0"].includes(item.gameType) && item.gameType
      );
      if (arr) {
        setTimeout(() => {
          this.$store.dispatch("setHotsGameListAc", arr);
        }, 500);
      }
    },
    viewMessage() {
      this.$router.push("/mail");
    },
    openMessage(val) {
      this.$confirm(val.content, val.title, {
        confirmButtonText: "查看全部公告",
        customClass: "el-pop-wid-message",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          if (!this.isLogin) return;
          this.$router.push("/mail");
        })
        .catch(() => {
          console.log(11);
        });
    },
    // banner
    getSilde() {
      const params = {
        position: 1,
        device: 1,
      };
      this.$Api.getSilde(params).then((res) => {
        if (res) {
          this.banners = res.slideList;
          this.autoBanner();
        }
      });
    },
    // 公告
    getTop50MessageList() {
      this.$Api.getTop50MessageList().then((res) => {
        if (res) {
          this.newsList = res.messageList;
          if (this.newsList && this.newsList.length) {
            // 设定24小时后重新开启公告通知弹窗
            const isDay = localStorage.getItem("messageAuto");
            if (!isDay && this.newsList.length) {
              this.ismsgShow = true;
            }
            if (isDay) {
              const targetDay = 24 * 60 * 60 * 1000; //一天秒数
              const isDYDay = new Date().getTime() - isDay; //获取当前日期是否超过一天
              if (isDYDay > targetDay) {
                localStorage.removeItem("messageAuto");
              }
            }
            this.$nextTick(() => {
              this.scrollInit();
            });
          }
        }
      });
    },
    moveScroll() {
      clearInterval(this.timerText);
    },
    // 跑马灯初始化
    scrollInit() {
      // 获取box宽度
      const dowSocrll = this.$refs.scorll.clientWidth;
      this.scorllWid = this.$refs.scorllWid.clientWidth;
      const domClone = this.$refs.scorllWid;
      this.isScroll = this.scorllWid > dowSocrll ? true : false;
      if (this.isScroll) {
        let dom = domClone.cloneNode(true);
        domClone.appendChild(dom);
        this.scrollStar();
      }
    },
    scrollStar() {
      if (!this.isScroll) return;
      this.timerText = setInterval(() => {
        const valNumber =
          this.scorllWid - this.scorllLeftVal < 10
            ? this.scorllWid - this.scorllLeftVal
            : 10;
        this.scorllLeftVal -= valNumber;
        if (this.scorllLeftVal === -this.scorllWid) {
          this.scorllLeftVal = 0;
          this.scrollLeftStar = "none";
          setTimeout(() => {
            this.scrollLeftStar = "all .4s";
          }, 50);
        }
      }, 120);
    },
    tabLive(val, index) {
      this.liveInd = index;
    },
    TabHotsGame(val, index) {
      this.hotsMove = "out";
      setTimeout(() => {
        this.hotsMoveXh = "";
        this.hotsGameInd = index;
        this.hotsMove = "move";
        setTimeout(() => {
          this.hotsMove = "upMove";
        }, 500);
      }, 500);
    },
    autoBanner() {
      this.bannerTimer = setInterval(() => {
        this.bannerInd++;
        if (this.bannerInd >= this.banners.length) {
          this.bannerInd = 0;
        }
      }, 5000);
    },
    tabBanner(val, index) {
      clearInterval(this.bannerTimer);
      this.bannerInd = index;
      this.autoBanner();
    },
    openBanner(val) {
      if (val.activityId != null) {
        this.$router.push("/activity/activityLandPage?type=" + val.activityId);
      }
    },
    tabPrev() {
      clearInterval(this.bannerTimer);
      if (this.bannerInd === 0) {
        this.bannerInd = this.banners.length;
      }
      this.bannerInd--;
      this.autoBanner();
    },
    tabNext() {
      clearInterval(this.bannerTimer);
      this.bannerInd++;
      if (this.bannerInd === this.banners.length) {
        this.bannerInd = 0;
      }
      this.autoBanner();
    },
    loadTab(val, index) {
      this.loadInd = index;
      const space = 100 * this.loads[index].class.length;
      this.loads.forEach((item) => {
        if (item.code === this.loadTargetName) {
          item.class = "out";
        }
      });
      setTimeout(() => {
        this.loadTargetName = val.code;
        this.loads.forEach((item) => {
          item.class = "";
          if (item.code === val.code) {
            item.class = "move";
          }
        });
      }, space);
    },
    // 打开游戏
    openGame(val, index) {
      // 游戏列表数量超过3个
      if (this.hotsGameList[this.hotsGameInd].list.length > 3 && index === 2) {
        const link = this.hotsGameList.find(
          (item) => Number(item.gameType) === val.gameType
        ).link;
        this.$router.push({
          path: link,
        });
        return;
      }
      if (val.gameType === 2) {
        this.$router.push({
          path: "/game/egame",
          query: {
            code: val.platType,
          },
        });
        this.isShowSelect = false;
        return;
      }
      if (val.gameType === 6) {
        this.$router.push({
          path: "/game/fishing",
          query: {
            code: val.platType,
          },
        });
        this.isShowSelect = false;
        return;
      }
      if (!this.isLogin) {
        this.$confirm("请先登录账号，才能了解更多", "温馨提示", {
          confirmButtonText: "确定",
          showCancelButton: false,
          customClass: "el-login-pop",
        })
          .then(() => {
            this.$router.push("/user/login");
          })
          .catch(() => {
            console.log(11);
          });
        return;
      }

      const params = {
        platType: val.platType,
        gameType: val.gameType,
        gameCode: val.gameCode,
        devices: 0,
      };
      this.$Api.getGameUrl(params).then((res) => {
        if (res) {
          const url = JSON.parse(res.data);
          window.open(url.url, "_blank");
        }
      });
    },
  },
  destroyed() {
    clearInterval(this.moveScroll);
  },
};
</script>

<style lang="scss" scoped>
.banner {
  height: 700px;
  position: relative;
  overflow: hidden;

  &:hover {
    .banner-next,
    .banner-prev {
      display: block;
    }
  }

  img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    height: 100%;
    opacity: 0;
    transition: all 0.8s;

    &.hover {
      opacity: 1;
      transform: translateX(-50%);
      z-index: 1;
    }
  }

  &-nav {
    white-space: nowrap;
    position: absolute;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;

    span {
      width: 50px;
      height: 4px;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      margin: 0 6px;
      background: rgba(102, 102, 102, 0.2);
      overflow: hidden;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        bottom: 0;
      }

      &.hover {
        &::before {
          animation: star 5s forwards;
          background: #2599f8;
        }
      }
    }
  }
}

@keyframes star {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

.banner-prev,
.banner-next {
  position: absolute;
  top: 50%;
  transform: translateY(-80%);
  width: 40px;
  height: 80px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0 8px 8px 0;
  backdrop-filter: blur(5px);
  z-index: 2;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border: 2px solid transparent;
    border-left-color: white;
    border-top-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:hover {
    background: rgba(0, 0, 0, 0.3);
  }
}

.banner-next {
  right: 0;
  border-radius: 8px 0 0 8px;

  &::before {
    transform: translate(-50%, -50%) rotate(135deg);
  }
}

.home-ad {
  background: rgba(81, 93, 105, 0.46);
  backdrop-filter: blur(5px);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;

  .yx-wid-12 {
    display: flex;
    align-items: center;
    height: 52px;
  }

  &-icon {
    width: 16px;
    height: 16px;
    margin-right: 6px;
    background: url("@/assets/images/icon-notice.webp") no-repeat center center;
    background-size: cover;
  }

  &-scroll {
    flex: 1;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    color: white;
    position: relative;
  }

  &-text {
    display: inline-block;
    white-space: nowrap;
    // width: 100%;
    // animation: amin 10s linear infinite;

    .row {
      display: inline-block;
      vertical-align: middle;
      margin-right: 20px;
      cursor: pointer;
    }
  }

  &-img {
    margin-top: 70px;
    cursor: pointer;
    text-align: center;

    img {
      transition: all 0.4s;

      &:hover {
        transform: scale(1.04);
      }
    }
  }
}

.home-load-flex {
  display: flex;
  align-items: center;

  .img {
    width: 880px;
  }

  .flex-left {
    flex: 1;

    .img {
      width: 115%;
      margin-left: -15%;
    }
  }

  .flex-right {
    background: url("@/assets/images/load-bg.png") no-repeat;
    background-size: 100% 100%;
    width: 456px;
    height: 580px;
    box-shadow: 0 6px 20px 3px rgba(201, 211, 237, 0.5);
    border-radius: 24px;
    flex: none;
    padding: 36px 24px;

    .load-tab {
      ul {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      li {
        width: 132px;
        height: 44px;
        background: url("@/assets/images/tab-bg.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #788b9d;
        font-size: 16px;
        cursor: pointer;
        margin: 0 10px;

        &.hover {
          color: white;
          background: url("@/assets/images/tab-bg1.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }

    .load-sec {
      padding: 0 10px;

      strong {
        font-size: 40px;
        margin-top: 32px;
        display: block;
      }

      p {
        line-height: 28px;
        font-size: 18px;
        margin-top: 12px;
        text-align: justify;
      }
    }

    .load-add {
      display: flex;
      align-items: center;
      padding: 24px 10px 0;

      &-code,
      &-web {
        background: #f4faff;
        border: 1px solid #fff;
        border-radius: 12px;
        padding: 23px 31px 12px;
        height: 224px;
        width: 182px;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;

        .text {
          text-align: center;
          line-height: 22px;

          span {
            font-size: 16px;
            margin-top: 20px;
            display: block;
          }

          p {
            font-size: 12px;
            margin-top: 2px;
            white-space: nowrap;
          }
        }
      }

      &-web {
        margin-left: 22px;

        .add {
          width: 120px;
          height: 120px;

          a {
            color: #2599f8;
            word-wrap: break-word;
          }
        }
      }

      &-code {
        .img {
          width: 120px;
        }
      }
    }
  }
}

.load-add-pc {
  padding: 0 10px;
  margin-top: 32px;

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  li {
    width: 120px;
    height: 168px;
    background: #f0f6ff;
    border: 1px solid #fff;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 60%;
    }

    .btn {
      background: url("@/assets/images/tab-bg1.png") no-repeat;
      line-height: 22px;
      height: 22px;
      width: 68px;
      font-size: 12px;
      background-size: 100% 100%;
      cursor: pointer;
      box-shadow: 0 2px 5px #5da7eb;
      border-radius: 11px;
      color: white;
      text-align: center;
      margin-top: 12px;
    }
  }
}

.yx-bg {
  background: url("@/assets/images/home-bg.jpg") center top repeat-y;
  background-size: 100% auto;
  padding-bottom: 80px;
}

@keyframes imgStar {
  0% {
    transform: translateX(10%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes imgOut {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(-10%);
    opacity: 0;
  }
}

@keyframes imgStarHots {
  0% {
    transform: translateX(20%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes imgOutHots {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(-20%);
    opacity: 0;
  }
}

.yx-load {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;

  &-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;

    &.move {
      z-index: 1;
      opacity: 1;

      .img {
        animation: imgStar 0.4s forwards;
        opacity: 0;
      }

      .bg {
        opacity: 0.8;
        transition: all 0.4s 0.3s ease;
      }
    }

    &.out {
      z-index: 1;
      opacity: 1;

      .img {
        animation: imgOut 0.8s forwards;
        transform: translateX(-150px);
        opacity: 0;
      }

      .bg {
        opacity: 0;
        transition: all 0.4s 0.3s ease;
      }
    }
  }

  &-left {
    position: relative;
    height: 644px;
    width: 818px;
    margin-top: 9px;
    flex: none;
    display: flex;
    align-items: center;

    .img {
      height: 100%;
      position: absolute;
      z-index: 2;
    }

    .bg {
      height: 100%;
      position: absolute;
      left: -80px;
      bottom: -10px;
      z-index: 1;
      opacity: 0;
    }
  }

  &-right {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-left: 60px;

    h3 {
      font-size: 40px;
      margin: 20px 0 50px;
      letter-spacing: 2px;
      color: #4b5e6f;
    }
  }

  &-menus {
    width: 300px;
    margin: 0 auto;
  }

  &-main {
    margin: 40px 0 30px;
    line-height: 1.75;

    p {
      text-align: left;
      font-size: 15px;
    }
  }

  &-row {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }

  &-item {
    width: 180px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;

    &:nth-child(2) {
      margin: 0 20px;
    }

    .code,
    .img {
      width: 180px;
      height: 180px;
      margin-bottom: 24px;
    }

    .code {
      padding: 30px;
      border-radius: 20px;
      background: url("@/assets/images/load/code.webp") no-repeat;
      background-size: cover;
      box-shadow: 0 10px 20px 0 #cad3dc, 0 -10px 18px 0 rgba(255, 255, 255, 0.3);
    }

    .img {
      img {
        width: 100%;
        margin: -20px 0 0;
      }
    }

    p {
      text-align: center;
    }
  }
}

// 热门游戏
.ab-hotgame {
  .ab-title {
    font-size: 40px;
    margin: 120px auto 0;
    font-weight: bold;
    text-align: center;
  }

  &-box {
    ul {
      position: relative;
      height: 625px;
      width: 100%;

      li {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &-title {
    position: absolute;
    left: 0;
    top: 204px;

    &.out {
      & > * {
        animation: imgOutHots 0.4s forwards;
      }
    }

    &.move {
      & > * {
        animation: imgStarHots 0.4s forwards;
      }
    }

    & > * {
      position: relative;
      animation: imgStarHots 0.4s forwards;
    }

    img {
      height: 180px;
    }

    p {
      color: #4b5e6f;
      line-height: 2;
      text-align: justify;
      font-size: 14px;
      width: 400px;
      margin-top: 28px;
    }
  }

  &-main {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &.out {
      img {
        animation: imgOutHots 0.4s forwards;
      }
    }

    &.move {
      img {
        animation: imgStarHots 0.4s forwards;
      }
    }

    &.upMove {
      img {
        &.upMode {
          animation: upMode 2.5s linear infinite;
        }

        &.downMode {
          animation: downMode 2.5s ease-in infinite;
        }
      }

      .seven {
        &.downMode {
          animation: downMode 2.5s ease-in infinite;
        }
      }

      .eigth {
        &.upMode {
          animation: downMode 2.5s ease-in infinite;
        }
      }

      // .
    }

    img {
      position: absolute;
      animation: imgStarHots 0.4s forwards;
    }

    &.realbet {
      .one {
        width: 672px;
        height: 622px;
        top: 70px;
        left: 343px;
      }

      .two {
        width: 80px;
        position: absolute;
        top: 180px;
        left: 465px;
      }

      .three {
        width: 57px;
        top: 170px;
        right: 440px;
      }
    }

    &.egame {
      .one {
        width: 600px;
        top: 55px;
        left: 381px;
        z-index: 1;
      }

      .two {
        width: 150px;
        top: 90px;
        left: 447px;
      }

      .bg {
        width: 622px;
        top: 233px;
        left: 460px;
      }
    }

    &.lottery {
      .one {
        width: 626px;
        left: 360px;
        bottom: 0px;
        z-index: 2;
      }

      .two {
        width: 187px;
        height: 335px;
        right: 360px;
        bottom: 21px;
        z-index: 3;
      }

      .three {
        width: 203px;
        height: 280px;
        top: 194px;
        left: 549px;
      }

      .four {
        width: 255px;
        height: 320px;
        top: 236px;
        left: 445px;
      }

      .five {
        width: 70px;
        top: 285px;
        left: 698px;
        z-index: 1;
      }

      .six {
        width: 340px;
        height: 448px;
        top: 90px;
        left: 660px;
      }

      .seven {
        width: 60px;
        top: 110px;
        right: 665px;
        position: absolute;

        img {
          width: 100%;
        }

        .show {
          right: 13px;
          top: 22px;
        }
      }

      .eigth {
        width: 60px;
        position: absolute;
        top: 180px;
        left: 485px;

        img {
          width: 100%;
        }

        .show {
          right: 22px;
          top: 28px;
        }
      }
    }

    &.sport {
      .one {
        width: 220px;
        position: absolute;
        top: 185px;
        left: 415px;
        z-index: 1;
      }

      .two {
        width: 485px;
        position: absolute;
        top: 145px;
        left: 443px;
        z-index: 2;
      }

      .three {
        width: 250px;
        position: absolute;
        top: 168px;
        left: 698px;
        z-index: 1;
      }

      .four {
        width: 100px;
        position: absolute;
        top: 560px;
        left: 660px;
        z-index: 11;
      }

      .five {
        width: 60px;
        position: absolute;
        bottom: -30px;
        left: 820px;
        z-index: 1;
      }

      .six {
        width: 40px;
        position: absolute;
        top: 510px;
        right: 430px;
        z-index: 1;
      }

      .seven {
        width: 40px;
        position: absolute;
        top: 380px;
        left: 410px;
      }

      .bg {
        width: 700px;
        height: 615px;
        position: absolute;
        top: 103px;
        left: 335px;
      }
    }

    &.gaming {
      .one {
        width: 1100px;
        top: 75px;
        left: 127px;
      }

      .two {
        width: 142px;
        top: 130px;
        left: 716px;
      }

      .three {
        width: 87px;
        top: 525px;
        right: 385px;
      }
    }

    &.fishing {
      .bg {
        width: 595px;
        position: absolute;
        left: 377px;
        top: 300px;
      }

      .one {
        width: 520px;
        position: absolute;
        top: 101px;
        left: 410px;
        z-index: 10;
      }

      .two {
        width: 84px;
        position: absolute;
        top: 205px;
        left: 430px;
      }

      .three {
        width: 58px;
        position: absolute;
        top: 410px;
        left: 845px;
      }

      .four {
        width: 140px;
        position: absolute;
        top: 500px;
        left: 895px;
      }
    }

    &.chess {
      .bg {
        width: 820px;
        top: 90px;
        left: 275px;
      }

      .one {
        width: 415px;
        top: 110px;
        left: 539px;
        z-index: 1;
      }

      .two {
        width: 145px;
        top: 260px;
        left: 415px;
        z-index: 2;
      }

      .three {
        width: 184px;
        top: 440px;
        right: 365px;
        z-index: 2;
      }

      .four {
        width: 200px;
        top: 490px;
        left: 370px;
        z-index: 3;
      }
    }
  }

  &-menus {
    right: 15px;
    position: absolute;
    bottom: 32px;

    dl {
      &.out {
        dd {
          animation: imgOutHots 0.4s forwards;
        }
      }

      &.move {
        dd {
          animation: imgStarHots 0.4s forwards;
        }
      }

      dd {
        width: 324px;
        height: 126px;
        margin-bottom: 20px;
        cursor: pointer;
        background: url("@/assets/images/home/hotsMenus.webp") no-repeat;
        background-size: 100%;
        position: relative;
        padding: 30px 0 0 150px;
        color: #828ebf;
        animation: imgStarHots 0.4s forwards;

        .icon {
          position: absolute;
          width: 130px;
          bottom: 38px;
          left: 12px;
        }

        strong {
          font-size: 20px;
          font-weight: normal;
        }

        p {
          font-size: 12px;
          transform: scale(0.75);
          transform-origin: left;
          margin-top: -3px;
        }
      }
    }
  }

  &-navs {
    width: 1000px;
    z-index: 11;
  }
}

@keyframes upMode {
  50% {
    transform: translateY(15px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes downMode {
  50% {
    transform: translateY(-15px);
  }

  100% {
    transform: translateY(0);
  }
}

.ab-service {
  margin: 100px auto 0;
  width: 100%;

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 310px;

      .load {
        width: 184px;
        height: 184px;
        background: url("@/assets/images/home/bg.webp") no-repeat;
        background-size: cover;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        box-shadow: 0 8px 12px 0 rgba(121, 149, 175, 0.4);

        .gif {
          width: 160px;
          height: 160px;
          position: absolute;
          top: 12px;
          left: 12px;
        }

        .time {
          position: absolute;
          text-align: center;
          color: white;
          line-height: 1;

          span {
            font-size: 48px;
          }
        }
      }

      .name {
        font-size: 18px;
        margin-top: 24px;
      }
    }
  }

  dl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 60px;

    dd {
      width: 310px;
      height: 380px;
      background: url("@/assets/images/home/servicebg.webp") no-repeat;
      background-size: cover;
      padding: 0 40px;
      display: flex;
      align-items: center;
      // justify-content: center;
      flex-direction: column;
      box-shadow: 0 20px 20px -10px #cdd2dd;
      border-radius: 20px;

      .icon {
        width: 102px;
        margin: 35px 0;
        border-radius: 20px;
        box-shadow: 0 16px 10px 0 rgba(214, 221, 235, 0.8),
          0 -4px 10px 0 #e5e7ec;

        img {
          width: 100%;
        }
      }

      .h3 {
        margin-bottom: 16px;
        text-shadow: 1px 1px 0 rgba(134, 161, 197, 0.4), -1px -1px 0 #fff;
        font-size: 24px;
        color: #4b5e6f;
      }

      .sec {
        margin-bottom: 0;
        text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.4), -1px -1px 1px #fff;
        font-size: 14px;
        line-height: 2;
        text-align: justify;
        user-select: none;
      }
    }
  }
}

.ab-parther {
  width: 100%;
  height: 480px;
  background: url("@/assets/images/home/parther01.webp") no-repeat;
  background-size: 1355px 480px;
  margin: 80px auto 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  transition: all 0.4s;

  &:hover {
    transform: scale(1.04);
  }

  img {
    height: 100%;
  }

  &-left {
    padding: 60px;
  }
}
</style>
