<template>
  <div class="header" :class="{ headerFlat: headerShow, isBg: isSponsor }">
    <div class="header-wid">
      <div class="logo" @click="$router.push('/')">
        <img v-if="baseInfo && baseInfo.length" :src="baseText('logo')" />
      </div>
      <div class="nav" v-if="menus">
        <ul>
          <li
            v-for="(item, index) in menus"
            :key="index"
            @mousemove="tabSelect(item, index)"
            :class="{ hover: $route.meta.data === item.layout }"
            @click="jumpWeb(item)"
          >
            {{ item.gameTypeName }}
          </li>
        </ul>
      </div>
      <div class="help">
        <ul>
          <li
            v-for="(item, index) in helps"
            :key="index"
            @click="jumpHelp(item)"
            @mouseenter="tabSelectHelp(item, index)"
          >
            <span
              class="img"
              :style="'background-image:url(' + item.img + ')'"
            ></span>
            <p class="text">{{ item.name }}</p>
          </li>
        </ul>
      </div>
      <div class="login" v-if="!isLogin">
        <div class="input">
          <input
            type="text"
            v-model="login.account"
            onKeyUp="value=value.replace(/[\W]/g,'')"
            :placeholder="$t('logins.zh')"
            :class="{ focus: login.account.length > 0 }"
          />
        </div>
        <div class="input">
          <input
            type="password"
            :placeholder="$t('logins.mm')"
            onKeyUp="value=value.replace(/[\W]/g,'')"
            v-model="login.pwd"
            :class="{ focus: login.pwd.length > 0 }"
          />
          <!-- <span class="pwd">忘记？</span> -->
        </div>
        <div class="input input-code">
          <input
              type="text"
              :placeholder="$t('logins.yzm')"
              maxlength="6"
              :class="{ focus: login.code.length > 0 }"
              v-model="login.code"
              onKeyUp="value=value.replace(/[\W]/g,'')"
          />
          <!-- @blur="inputBlur" -->
          <img
              v-show="isCode"
              :src="codeImg"
              class="code"
              @click="getCaptcha"
          />
        </div>
        <div class="btn-log" @click="loginSend">{{ $t("logins.dl") }}</div>
        <div class="btn-reg" @click="jumpReg">{{ $t("logins.zc") }}</div>
      </div>
      <div class="login-nav" v-if="isLogin">
        <ul class="login-nav-ul">
          <li @click="$router.push('/finance/deposit')">
            <span>
              <img src="@/assets/images/mine/icon01-1.png" class="mr" />
              <img src="@/assets/images/mine/icon01-2.png" class="xz" />
            </span>
            <p>{{ $t("components.Header.877183-0") }}</p>
          </li>
          <li @click="$router.push('/finance/transfer')">
            <span>
              <img src="@/assets/images/mine/icon02-1.png" class="mr" />
              <img src="@/assets/images/mine/icon02-2.png" class="xz" />
            </span>
            <p>{{ $t("components.Header.877183-1") }}</p>
          </li>
          <li @click="$router.push('/finance/withdrawal')">
            <span>
              <img src="@/assets/images/mine/icon03-1.png" class="mr" />
              <img src="@/assets/images/mine/icon03-2.png" class="xz" />
            </span>
            <p>{{ $t("components.Header.877183-2") }}</p>
          </li>
        </ul>
        <div class="login-info">
          <div class="info" v-if="userInfo">
            <span class="name"
              >{{ userInfo.userName
              }}<span
                class="level-img"
                v-if="userInfo.levelId"
                :style="{
                  backgroundImage:
                    'url(' +
                    require('@/assets/images/mine/level/vip' +
                      userInfo.levelId +
                      '.webp') +
                    ')',
                }"
              ></span
            ></span>
            <p class="text-ak" v-if="accountMoney">
              ¥{{
                accountMoney.userBalance
                  ? accountMoney.userBalance.toFixed(2)
                  : "0.00"
              }}
              <em></em>
            </p>
          </div>
          <img v-if="userInfo" v-lazy="userInfo.photoUrl" class="photo" />
          <div class="login-menus">
            <ul>
              <li
                v-for="(item, index) in loginMenu"
                @click="$router.push(item.link)"
                :key="index"
              >
                <img :src="item.icon" class="mr" />
                <img :src="item.iconHover" class="xz" />
                <span class="text">{{ item.name }}</span>
                <span class="number"></span>
              </li>
            </ul>
            <div class="login-out" @click="loginOut">
              {{ $t("components.Header.877183-3") }}
            </div>
          </div>
        </div>
      </div>
      <div class="lang" v-if="langList">
        <el-select
          class="lang-style"
          v-model="langVal"
          :placeholder="$t('components.Header.877183-4')"
          @change="tabLange"
        >
          <el-option
            v-for="item in langList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <!--      <div class="lang" v-if="langList">-->
      <!--        <el-select class="lang-style" v-model="areaVal" :placeholder="$t('components.Header.877183-4')" @change="tabArea">-->
      <!--          <el-option v-for="item in areaList" :key="item.value" :label="item.name" :value="item.value">-->
      <!--          </el-option>-->
      <!--        </el-select>-->
      <!--      </div>-->
    </div>

    <!-- 下拉导航 -->
    <div class="nav-bg" :class="{ fadeIn: isShowSelect }" @mouseleave="moveOut">
      <div class="yx-wid-12">
        <!-- 样式1 -->
        <div
          class="nav-list"
          v-if="
            [
              'sport',
              'realbet',
              'chess',
              'gaming',
              'lottery',
              'egame',
              'fishing',
            ].includes(selectType)
          "
        >
          <div
            class="arrow prev"
            @click="arrowNavTab('prev')"
            v-if="navIndex > 0"
          ></div>
          <div
            class="arrow next"
            @click="arrowNavTab('next')"
            v-if="navIndex !== moveNavWid && isNavShow"
          ></div>
          <div class="yx-wid-12 nav-list-box" ref="targetTotal">
            <div
              class="nav-list-scroll"
              :style="{ transform: 'translateX(' + menuLeftVal + 'px)' }"
            >
              <div
                class="nav-row"
                v-for="(item, index) in menus[menuInd].list"
                :key="index"
                ref="targetGame"
              >
                <!-- style2: item.gameType === 3 -->
                <!-- :class="{
                style: [2, 3, 6, 7].includes(menuInd),
                style1: menuInd === 6,
              }" -->
                <div class="left" @click="openGame(item)">
                  <img
                    v-if="item.pcNavbarImgUrl"
                    v-lazy="item.pcNavbarImgUrl"
                    class="img"
                  />
                  <img
                    v-else
                    src="@/assets/images/menus/menus01.webp"
                    class="img"
                  />

                  <!-- <img src="@/assets/images/menus/menus01.webp" class="img" /> -->
                </div>
                <div class="right">
                  <div class="nav-game-icon">
                    <!-- <div class="icon">
                      <img :src="item.icon" />
                    </div> -->
                    <div class="text">
                      <span>{{ item.apiTitle }}</span>
                      <p>{{ item.eng }}</p>
                    </div>
                  </div>
                  <div class="link">
                    <a
                      href="javascript:;"
                      @click="openGame(item)"
                      class="btn-default"
                      >{{ $t("components.Header.877183-5") }}</a
                    >
                  </div>
                </div>
              </div>
              <div class="nav-ty-img" v-if="menuInd === 1">
                <img src="@/assets/images/menus/04.png" />
              </div>
            </div>
          </div>
        </div>
        <!-- 合营 -->
        <div
          class="help-nav"
          v-if="selectType === 'agent' && baseInfo && baseInfo[2]"
        >
          <div class="left">
            <img
              v-lazy="baseText('webJointPlanImg')"
              class="link"
              @click="jumpHelp('/agent')"
            />
            <div class="title">
              <span>{{
                baseText("keywords") + $t("components.Header.877183-6")
              }}</span>
              <p>{{ $t("components.Header.877183-7") }}</p>
              <img src="@/assets/images/agent/02.png" />
            </div>
          </div>
          <div class="right" v-if="baseInfo && baseInfo[21]">
            <div class="row">
              <span>IOS APP</span>
              <QRCode
                v-if="baseInfo && baseInfo[22].configValue"
                :text="baseText('iosApp')"
                :width="110"
                :height="110"
              />
              <p>
                {{ $t("components.Header.877183-8") }}<br />
                {{ baseText("iosApp") }}
              </p>
            </div>

            <div class="row">
              <span> Andriod APP</span>
              <QRCode
                v-if="baseInfo && baseInfo[21].configValue"
                :text="baseText('androidApp')"
                :width="110"
                :height="110"
              />
              <p>
                {{ $t("components.Header.877183-9") }}<br />
                {{ baseText("androidApp") }}
              </p>
            </div>
          </div>
        </div>
        <!-- 赞助 -->
        <div class="help-nav" v-if="selectType === 'madrid'">
          <img
            v-if="baseInfo"
            v-lazy="baseText('webSponsor')"
            class="link-img"
            @click="jumpHelp('/sponsor/madrid')"
          />
        </div>
        <!-- 优惠 -->
        <div class="help-nav" v-if="selectType === 'discount'">
          <ul class="help-nav-discount">
            <li @click="jumpHelp('/activity/discount?type=2')">
              <img src="@/assets/images/menus/36.png" class="link-img" />
            </li>
            <li @click="jumpHelp('/activity/discount?type=5')">
              <img src="@/assets/images/menus/37.png" class="link-img" />
            </li>
            <li @click="jumpHelp('/mine/details')">
              <img src="@/assets/images/menus/38.png" class="link-img" />
            </li>
<!--            <li @click="jumpHelp('/inviteFriends')">-->
<!--              <img src="@/assets/images/menus/39.png" class="link-img" />-->
<!--            </li>-->
          </ul>
        </div>
        <!-- App -->
        <div class="help-nav" v-if="selectType === 'download'">
          <div class="help-nav-download">
            <div class="row">
              <img
                v-if="baseInfo && baseInfo[53].configValue"
                v-lazy="baseText('webNavImg1')"
                class="left link-img"
                @click="jumpHelp('/download?type=0')"
              />
              <div class="right">
                <span class="title">IOS APP</span>
                <QRCode
                  v-if="baseInfo && baseInfo[21].configValue"
                  :text="baseText('iosApp')"
                  :width="88"
                  :height="88"
                />
                <p>
                  <span class="small">{{
                    $t("components.Header.877183-10")
                  }}</span>
                  <a :href="baseText('iosApp')" target="_blank">{{
                    baseText("iosApp")
                  }}</a>
                </p>
              </div>
            </div>
            <div class="row">
              <img
                v-if="baseInfo && baseInfo[53].configValue"
                v-lazy="baseText('webNavImg2')"
                class="left link-img"
                @click="jumpHelp('/download?type=1')"
              />
              <div class="right">
                <span class="title">Andriod APP</span>
                <QRCode
                  v-if="baseInfo && baseInfo[21].configValue"
                  :text="baseText('androidApp')"
                  :width="88"
                  :height="88"
                />
                <p>
                  <span class="small">{{
                    $t("components.Header.877183-11")
                  }}</span>
                  <a :href="baseText('androidApp')" target="_blank">{{
                    baseText("androidApp")
                  }}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 客服弹窗 -->
    <div class="contact-box" v-if="jumpContact">
      <div class="contact-main">
        <img src="@/assets/images/contact01.png" />
        <div class="main">
          <h2>{{ $t("components.Header.877183-12") }}</h2>
          <p class="text-red">
            {{ $t("components.Header.877183-13") }}
          </p>
          <p>
            {{ $t("components.Header.877183-14")
            }}<span class="text-red">{{
              $t("components.Header.877183-15")
            }}</span
            >{{ $t("components.Header.877183-16") }}
          </p>
          <el-button class="link" type="primary" @click="jumpContactWeb">{{
            $t("components.Header.877183-17")
          }}</el-button>
        </div>
      </div>
    </div>

<!--    <Slider-->
<!--      v-if="show"-->
<!--      :visible.sync="show"-->
<!--      ref="sliderComponent"-->
<!--      :fromValue="login"-->
<!--      @sumit="validImg"-->
<!--      :log="true"-->
<!--    ></Slider>-->
  </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
import { removeToken, setToken } from "@/utils/token";
import { mapState } from "vuex";
import QRCode from "@/components/QRCode.vue";
// import Slider from "@/components/Slider.vue";
import i18n from "@/lang";
export default {
  components: { QRCode },
  data() {
    return {
      // show: false,
      login: {
        account: "",
        pwd: "",
        code: "",
      },
      menuInd: 0,
      targetMenuInd: false,
      menuLeftVal: 0,
      helps: [
        {
          name: this.$i18n.t("helpNav.kf"),
          linka: "link",
          layout: "",
          img: require("@/assets/images/nav01.png"),
        },
        {
          name: this.$i18n.t("helpNav.hy"),
          linka: "/agent",
          layout: "agent",
          img: require("@/assets/images/nav02.png"),
        },
        // {
        //   name: "赞助",
        //   linka: "/sponsor/madrid",
        //   layout: "madrid",
        //   img: require("@/assets/images/nav03.png"),
        // },
        {
          name: this.$i18n.t("helpNav.yh"),
          linka: "/activity/discount",
          layout: "discount",
          img: require("@/assets/images/nav04.png"),
        },
        {
          name: "APP",
          linka: "/download",
          layout: "download",
          img: require("@/assets/images/nav05.png"),
        },
      ],
      headerShow: false,
      isShowSelect: false,
      navIndex: 0,
      navTotalWid: 0,
      navWid: 0,
      isNavShow: false,
      moveNavWid: 0,
      moveYnumber: 0,
      isSponsor: false,
      selectType: "",
      loginMenu: [
        {
          name: this.$t("components.Header.877183-18"),
          icon: require("@/assets/images/mine/icon04.png"),
          iconHover: require("@/assets/images/mine/icon04-1.png"),
          link: "/mine/userInfo",
        },
        {
          name: this.$t("components.Header.877183-19"),
          icon: require("@/assets/images/mine/icon05.png"),
          iconHover: require("@/assets/images/mine/icon05-1.png"),
          link: "/mine/vip",
        },
        {
          name: this.$t("components.Header.877183-20"),
          icon: require("@/assets/images/mine/icon06.png"),
          iconHover: require("@/assets/images/mine/icon06-1.png"),
          link: "/record/welfare",
        },
        {
          name: this.$t("components.Header.877183-21"),
          icon: require("@/assets/images/mine/icon07.png"),
          iconHover: require("@/assets/images/mine/icon07-1.png"),
          link: "/record/betRecord",
        },
        {
          name: this.$t("components.Header.877183-22"),
          icon: require("@/assets/images/mine/icon08.png"),
          iconHover: require("@/assets/images/mine/icon08-1.png"),
          link: "/record/transRecord",
        },
        {
          name: this.$t("components.Header.877183-23"),
          icon: require("@/assets/images/mine/icon09.png"),
          iconHover: require("@/assets/images/mine/icon09-1.png"),
          link: "/mail",
        },
      ],
      codeUUid: "",
      codeImg: "",
      jumpContact: false,
      isCode: false,
      menuIndex: 1,
      isLoadOver: false,
      menus: null,
      langList: null, //语言列表
      langVal: "", // 语言值
      areaList: null,
      areaVal: "",
    };
  },
  watch: {
    $route(to) {
      if (!this.menus) return;
      this.menus.forEach((item) => {
        item.isSelect = false;
        if (to.meta.data === item.layout) {
          item.isSelect = true;
          this.selectType = to.meta.data;
        }
      });
      this.watchSponsorRoute(to.meta.layout);
    },
    userInfo(val) {
      this.getArea(val);
    },
  },
  computed: {
    ...mapState([
      "isLogin",
      "userInfo",
      "accountMoney",
      "baseInfo",
      "languages",
    ]),
  },
  mounted() {
    addEventListener("scroll", this.watchScroll);
    this.watchSponsorRoute(this.$route.meta.layout);
    this.getCaptcha();
    this.getUserBalance();
    // this.getAllPlatTypes();

    this.getApiGameCategoryList();
    this.getLang();
    this.getArea();
  },
  methods: {
    tabLange(e) {
      if (!this.isLogin) {
        this.$confirm(
          this.$t("components.Header.877183-25"),
          this.$t("components.Header.877183-26"),
          {
            confirmButtonText: this.$t("components.Header.877183-27"),
            showCancelButton: false,
            customClass: "el-pop-wid",
          }
        )
          .then(() => {
            this.$router.push("/user/login");
          })
          .catch(() => {
            console.log(11);
          });
        return;
      }
      this.changeLanguageAndCurrenc(e, "lang");
    },
    tabArea(e) {
      if (!this.isLogin) {
        this.$confirm(
          this.$t("components.Header.877183-25"),
          this.$t("components.Header.877183-26"),
          {
            confirmButtonText: this.$t("components.Header.877183-27"),
            showCancelButton: false,
            customClass: "el-pop-wid",
          }
        )
          .then(() => {
            this.$router.push("/user/login");
          })
          .catch(() => {
            console.log(11);
          });
        return;
      }
      this.changeLanguageAndCurrenc(e, "area");
    },
    changeLanguageAndCurrenc(val, type) {
      let params = {};
      if (type === "lang") {
        params.languageType = val;
      }
      if (type === "area") {
        params.currencyType = val;
      }
      this.$Api.changeLanguageAndCurrenc(params).then((res) => {
        if (res) {
          if (type === "lang") {
            let langtarget = this.languages.find(
              (item) => item.id === val
            ).type;
            this.$i18n.locale = langtarget;
            localStorage.setItem("lang", langtarget);
          }
          location.reload();
        }
      });
    },
    // 语言数据
    getLang() {
      this.$Api
        .getSysDictionary({ dictionaryValue: "language_type" })
        .then((res) => {
          if (res) {
            // 本地是否有设置语言
            const getlang = localStorage.getItem("lang");
            let localVal = "";
            if (getlang) {
              localVal = this.languages.find(
                (item) => item.type === getlang
              ).id;
            }
            this.langList = res.sysDictionaryList;
            console.log(this.langList, localVal);
            // 语言初始值
            const isLang = res.sysDictionaryList.find(
              (item) => item.value === localVal
            );
            if (isLang) {
              this.langVal = localVal
                ? res.sysDictionaryList.find((item) => item.value === localVal)
                    .name
                : res.sysDictionaryList[0].name;
            }
          }
        });
    },

    // 货币数据
    getArea(val) {
      this.$Api
        .getSysDictionary({ dictionaryValue: "currency_type" })
        .then((res) => {
          if (res) {
            // 本地是否有设置语言
            const getlang = localStorage.getItem("area");
            this.areaList = res.sysDictionaryList;
            let localVal = "";
            if (val) {
              localVal = val.currencyType;
            }
            // 语言初始值
            this.areaVal = localVal
              ? res.sysDictionaryList.find((item) => item.value === localVal)
                  .name
              : res.sysDictionaryList[0].name;
          }
        });
    },
    getApiGameCategoryList() {
      // 设定导航栏字段
      const layouts = [
        {
          aid: "1",
          type: "realbet",
          link: "/game/realbet",
          name: this.$i18n.t("navList.sx"),
        },
        {
          aid: "2",
          type: "egame",
          link: "/game/egame?code=ag",
          name: this.$i18n.t("navList.dz"),
        },
        {
          aid: "3",
          type: "lottery",
          link: "/game/lottery",
          name: this.$i18n.t("navList.cp"),
        },
        {
          aid: "4",
          type: "sport",
          link: "/game/sport",
          name: this.$i18n.t("navList.ty"),
        },
        {
          aid: "5",
          type: "gaming",
          link: "/game/gaming",
          name: this.$i18n.t("navList.dj"),
        },
        {
          aid: "6",
          type: "fishing",
          link: "/game/fishing?code=bg",
          name: this.$i18n.t("navList.yl"),
        },
        {
          aid: "7",
          type: "chess",
          link: "/game/chess",
          name: this.$i18n.t("navList.qp"),
        },
      ];
      this.$Api.getApiGameCategoryList().then((res) => {
        if (res) {
          res.apiGameCategoryList.forEach((item) => {
            layouts.find((row) => {
              if (item.gameType === row.aid) {
                item.layout = row.type;
                item.link = row.link;
                // item.gameTypeName = row.name
              }
            });
          });
          this.menus = res.apiGameCategoryList.filter(
            (item) => item.gameType !== "0"
          );
          this.menus.unshift({
            gameTypeName: i18n.t("components.Header.877183-24"),
            link: "/",
            list: [],
            layout: "home",
          });
          this.menus.forEach((item) => {
            layouts.find((row) => {
              if (item.gameType === row.aid) {
                item.gameTypeName = row.name;
              }
            });
          });
          this.getAllPlatTypes();
        }
      });
    },
    // 打开游戏
    openGame(val) {
      if (val.gameType === 2) {
        this.$router.push({
          path: "/game/egame",
          query: {
            code: val.platType,
          },
        });
        this.isShowSelect = false;
        return;
      }
      if (val.gameType === 6) {
        this.$router.push({
          path: "/game/fishing",
          query: {
            code: val.platType,
          },
        });
        this.isShowSelect = false;
        return;
      }
      if (!this.isLogin) {
        this.$confirm(
          this.$t("components.Header.877183-25"),
          this.$t("components.Header.877183-26"),
          {
            confirmButtonText: this.$t("components.Header.877183-27"),
            showCancelButton: false,
            customClass: "el-pop-wid",
          }
        )
          .then(() => {
            this.$router.push("/user/login");
          })
          .catch(() => {
            console.log(11);
          });
        return;
      }

      const params = {
        platType: val.platType,
        gameType: val.gameType,
        gameCode: val.gameCode,
        devices: 0,
      };
      this.$Api.getGameUrl(params).then((res) => {
        const url = JSON.parse(res.data);
        window.open(url.url, "_blank");
      });
    },
    // 导航
    async getAllPlatTypes() {
      this.$store.dispatch("setNavsValAc", false);
      if (this.menuIndex === 8 && this.menus.length && this.isLoadOver) {
        this.$store.dispatch("setNavsValAc", true);
        return;
      }
      this.isLoadOver = false;
      const params = {
        gameType: this.menuIndex,
        device: 1,
      };
      await this.$Api.getAllPlatTypes(params).then((res) => {
        let obj = [];
        if (res) {
          Object.keys(res.platTypes).map((item) => {
            obj.push(res.platTypes[item]);
          });
          obj.forEach((item) => {
            Object.assign(item, {
              icon: require("@/assets/images/gameicon/" +
                item.platType +
                ".png"),
            });
          });
          // 改造后的导航
          this.menus.forEach((item) => {
            if (Number(item.gameType) === this.menuIndex) {
              item.list = obj;
            }
          });
          this.menuIndex++;
          this.getAllPlatTypes();
          if (this.menuIndex === 7) {
            this.$store.dispatch("setNavsListAc", this.menus);
            this.isLoadOver = true;
          }
        }
      });
    },
    inputBlur() {
      setTimeout(() => {
        this.isCode = false;
      }, 100);
    },
    // 查看code
    inputCode() {
      this.isCode = true;
    },
    getUserBalance() {
      if (!this.isLogin) return;
      this.$Api.getUserBalance().then((res) => {
        if (res) {
          this.$store.dispatch("setAccountMoneyAc", res.userBalanceMap);
        }
      });
    },
    jumpContactWeb() {
      this.jumpContact = false;
      // const url = this.$router.resolve("/customer/main");
      window.open(this.baseInfo[6].configValue, "_blank");
    },
    async getCaptcha() {
      this.codeUUid = uuidv4();
      await this.$Api.getCaptcha(this.codeUUid).then((res) => {
        if (res) {
          let imgUrl =
              "data:image/png;base64," +
              btoa(
                  new Uint8Array(res).reduce(
                      (data, byte) => data + String.fromCharCode(byte),
                      ""
                  )
              );
          this.codeImg = imgUrl;
        }
      });
    // checkcode() {
    //   const { account, pwd } = this.login;
    //   if (!(account && pwd)) return;
    //   this.show = true;
    // },
    // validImg(val) {
    //   this.codeUUid = val;
    //   this.loginSend();
    },
    loginSend() {
      const { account, pwd, code } = this.login;
      if (!(account && pwd && code)) return;
      const params = {
        userName: account,
        password: pwd,
        code: code,
        codeId: this.codeUUid,
      };
      this.$Api.loginSend(params).then((res) => {
        if (res) {
          setToken(res.token);
          this.$store.dispatch("setHasLoginAc", true);
          this.$message({
            message: this.$t("components.Header.877183-28"),
            type: "success",
          });
          this.login = {
            account: "",
            pwd: "",
            code: "",
          };
          this.getUserBalance();
          this.$Api.getUserInfo().then((res) => {
            if (res) {
              this.$store.dispatch("setUserInfoAc", res.userInfo);
              // let langtarget = this.languages.find(item => item.id === res.userInfo.languageType).type
              // this.$i18n.locale = langtarget
              // localStorage.setItem('lang', langtarget)
              location.reload();
            }
          });
          this.$router.push("/");
          return;
        }
        this.getCaptcha();
      });
    },
    tabSelectHelp(val, index) {
      if (val.layout) {
        this.isShowSelect = true;
      } else {
        this.isShowSelect = false;
      }
      this.selectType = val.layout;
    },
    watchScroll(e) {
      const top = document.body.scrollTop || document.documentElement.scrollTop;
      if (top > 50) {
        this.headerShow = true;
      } else {
        this.headerShow = false;
      }
    },
    watchSponsorRoute(val) {
      if (val === "sponsor") {
        this.isSponsor = true;
      }
    },
    tabSelect(val, index) {
      this.selectType = val.layout;
      this.menuInd = index;
      this.menuLeftVal = 0;
      this.navIndex = 0;
      if (this.menus[index].list && this.menus[index].list.length) {
        this.isShowSelect = true;
        const rows = this.$refs.targetGame;
        if (rows && rows.length) {
          const number = rows.length;
          // 单个宽度
          this.navWid = rows[0].clientWidth + 20;
          // 取总长
          this.navTotalWid = this.navWid * number;
          // 取余数
          this.moveYnumber = (this.navTotalWid - 1200) % this.navWid;
          // 可移动的长度
          const length = Math.ceil((this.navTotalWid - 1200) / this.navWid);
          if (length > 0) {
            this.moveNavWid = length;
          } else {
            this.moveNavWid = 0;
          }
          // 判断是否显示左右切换
          const isShowNavTab = (rows[0].clientWidth + 20) * number > 1200;
          if (isShowNavTab) {
            this.isNavShow = true;
          }
        }
      } else {
        this.isShowSelect = false;
      }
    },
    arrowNavTab(val) {
      // 上下切换
      if (val === "prev") {
        if (this.navIndex === 0) return;
        this.navIndex--;
        this.menuLeftVal = -this.navWid * this.navIndex;
      } else {
        this.navIndex++;
        if (this.moveNavWid < this.navIndex) return;
        this.menuLeftVal = -this.navWid * this.navIndex;
        // console.log(this.navIndex, 8888, this.moveNavWid);
      }
    },
    moveOut() {
      this.isShowSelect = false;
    },
    jumpWeb(val) {
      // console.log(this.$route, val.layout);
      // if (this.$route.meta.data === val.layout) return;
      this.$router.push(val.link);
      this.isShowSelect = false;
    },
    jumpReg() {
      this.$router.push("/user/register");
    },
    jumpHelp(val) {
      // 外跳客服
      if (val.linka === "link") {
        this.jumpContact = true;
        return;
      }
      if (
        !this.isLogin &&
        ["/mine/details", "/activity/bettingGift"].includes(val)
      ) {
        this.$confirm(
          this.$t("components.Header.877183-25"),
          this.$t("components.Header.877183-26"),
          {
            confirmButtonText: this.$t("components.Header.877183-27"),
            showCancelButton: false,
            customClass: "el-pop-wid",
          }
        )
          .then(() => {
            this.$router.push("/user/login");
          })
          .catch(() => {
            // console.log(11);
          });
        return;
      }
      const url = val.linka ? val.linka : val;
      this.$router.push(url);
      this.isShowSelect = false;
    },
    loginOut() {
      this.$message({
        message: this.$t("components.Header.877183-29"),
        type: "success",
      });
      removeToken();
      location.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
  padding: 16px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 12;
  transition: all 0.4s;

  &-wid {
    display: flex;
    align-items: center;
    max-width: 1500px;
    width: 100%;
    padding: 0;
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }

  .logo {
    img {
      height: 44px;
    }
  }

  .nav,
  .help {
    flex: 1;

    ul {
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 18px;

      li {
        font-size: 16px;
        position: relative;

        &:hover {
          color: #2599f8;

          &::before {
            width: 100%;
          }
        }
      }
    }
  }

  .nav {
    ul {
      margin-left: 40px;

      li {
        margin: 0 8px;

        &::before {
          content: "";
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          width: 0%;
          bottom: -6px;
          height: 2px;
          background: #2599f8;
          border-radius: 2px;
          transition: all 0.3s;
        }

        &.hover {
          color: #2599f8;

          &::before {
            width: 100%;
          }
        }
      }
    }
  }

  .help {
    flex: none;

    ul {
      li {
        font-size: 16px;
        margin: 0 6px;
        text-align: center;
      }
    }

    .img {
      width: 24px;
      height: 24px;
      display: inline-block;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center center;
    }

    .text {
      position: relative;
      margin-top: -8px;
      font-size: 13px;
    }
  }

  .login {
    display: flex;
    align-items: center;

    .input {
      position: relative;
      margin-left: 10px;

      &-code {
        &:hover {
          .code {
            display: block !important;
          }
        }
      }

      input {
        background: #788b9d80;
        height: 28px;
        width: 100px;
        border-radius: 15px;
        padding: 0 10px;
        position: relative;
        z-index: 2;

        &:focus,
        &:hover,
        &.focus {
          background: #788b9d;
          color: white;
        }
      }

      .code {
        position: absolute;
        top: 28px;
        right: 0;
        left: 0;
        width: 100px;
        border-radius: 20px;
        height: 32px;
        cursor: pointer;
      }

      .pwd {
        position: absolute;
        top: 50%;
        right: 4px;
        transform: translateY(-54%);
        font-size: 12px;
        color: white;
        z-index: 3;
      }
    }

    .btn-log,
    .btn-reg {
      width: 54px;
      height: 28px;
      border-radius: 14px;
      background: white;
      color: #6c7ba8;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 4px 8px 0 rgba(194, 211, 222, 0.67), inset 0 1px 7px 0 #fff,
        inset 0 -3px 4px 0 rgba(175, 194, 230, 0.16);
      margin-left: 10px;
      cursor: pointer;
    }

    .btn-log {
      background: url("@/assets/images/btn-bg.png");
      background-size: 100% 100%;
      color: white;
      box-shadow: none;
      filter: drop-shadow(0 2px 4px rgba(93, 167, 235, 0.8));
    }
  }
}

.headerFlat {
  background: linear-gradient(
    to bottom,
    #8d9ea9,
    #d8d8d8 60%,
    rgba(192, 208, 219, 0)
  );
}

.nav-bg {
  background: url("@/assets/images/menus-bg.webp") no-repeat center;
  background-size: 100% 100%;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
  transition: all 0.4s;
}

.nav-list {
  padding-top: 100px;
  position: relative;
  user-select: none;
  height: 380px;

  .arrow {
    position: absolute;
    top: 55%;
    left: 0;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 100%;
    z-index: 10;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      border-right: 3px solid rgba(255, 255, 255, 0.6);
      border-bottom: 3px solid rgba(255, 255, 255, 0.6);
      top: 50%;
      left: 50%;
      transform: translate(-60%, -50%) rotate(-45deg);
    }

    &.next {
      left: auto;
      right: 0;
      margin-right: -40px;
    }

    &.prev {
      margin-left: -40px;
      transform: translate(-60%, -50%) rotate(180deg);
    }
  }

  &-box {
    overflow: hidden;
    position: relative;
    height: 100%;
  }

  &-scroll {
    width: 100%;
    height: 100%;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.4s;
  }
}

.nav-row {
  margin-right: 20px;
  display: flex;
  align-items: center;
  // width: 360px;
  height: 100%;
  flex: none;

  &:last-child {
    margin-right: 0;
  }

  .img {
    transition: all 0.4s;
    position: relative;
    // width: 100%;
    height: 100%;

    &:hover {
      transform: scale(1.04);
    }
  }

  .left {
    // width: 210px;
    height: 100%;
    cursor: pointer;
  }

  .right {
    display: flex;
    flex-direction: column;

    .link {
      margin-top: 16px;

      .btn-default {
        height: 32px;
        width: 100px;
        font-size: 14px;
      }
    }
  }

  // &.style2 {
  //   margin-right: 96px;
  // }
}

.nav-game-icon {
  display: flex;
  flex-direction: column;

  .icon {
    width: 48px;
    height: 48px;
    flex: none;
    border-radius: 8px;
    background: linear-gradient(180deg, white, #ebedfb);
    border-bottom: 2px solid white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 80%;
      filter: invert(30%);
    }
  }

  .text {
    margin-left: 10px;

    span {
      font-size: 28px;
      font-weight: bold;
      white-space: nowrap;
    }

    p {
      font-size: 12px;
      white-space: nowrap;
    }
  }
}

.nav-ty-img {
  width: 130px;
  margin-left: 40px;
  margin-right: -40px;

  img {
    width: 100%;
  }
}

.style {
  flex-direction: column;
  width: 180px;

  .left {
    order: 2;
    width: 170px;
  }

  .link {
    display: none;
  }
}

.style1 {
  .left {
    width: 150px;
    margin-top: 10px;
  }

  .img {
    width: 100%;
  }
}

.fadeIn {
  transform: translateY(0);
}

.isBg {
  background: white;
}

.help-nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 100px;

  .left {
    display: flex;
    align-items: center;

    img {
      width: 240px;
      cursor: pointer;

      &.link {
        transition: all 0.3s;

        &:hover {
          transform: scale(1.04);
        }
      }
    }

    .title {
      margin-left: 50px;

      span {
        font-size: 40px;
        font-weight: bold;
      }

      p {
        font-size: 24px;
        margin: 10px 0 20px;
      }

      img {
        width: 400px;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;

    .row {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 16px;

      span {
        font-size: 18px;
        font-weight: bold;
        display: block;
        margin-bottom: 10px;
      }

      img {
        width: 110px;
      }

      p {
        text-align: center;
        font-size: 12px;
        margin-top: 10px;
        line-height: 20px;
      }
    }
  }
}

.link-img {
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    transform: scale(1.04);
  }
}

.help-nav-discount {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  li {
    display: flex;
    flex-direction: column;

    .link-img {
      width: 317px;
    }
  }
}

.help-nav-download {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .row {
    display: flex;
    align-items: center;
    margin: 0 30px;

    .left {
      width: 240px;
      margin-right: 16px;
    }

    .right {
      display: flex;
      flex-direction: column;

      .title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .code {
        width: 88px;
        height: 88px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 12px;
        white-space: nowrap;
        text-align: center;
      }

      p {
        text-align: center;
        margin-top: 8px;
        font-size: 12px;
      }

      .small {
        white-space: nowrap;
        display: block;
      }

      a {
        color: #2599f8;
      }
    }
  }
}

.login-nav {
  display: flex;
  align-items: center;
  margin-left: 8px;

  .login-nav-ul {
    display: flex;
    align-items: center;

    li {
      margin-left: 12px;
      cursor: pointer;

      span {
        width: 24px;
        height: 24px;
        display: block;

        img {
          width: 100%;
          height: 100%;

          &.xz {
            display: none;
          }
        }
      }

      p {
        font-size: 12px;
      }

      &:hover {
        p {
          color: #2599f8;
        }

        .xz {
          display: block !important;
        }

        .mr {
          display: none !important;
        }
      }
    }
  }

  .login-info {
    display: flex;
    align-items: center;
    margin-left: 24px;
    cursor: pointer;
    position: relative;

    &:hover {
      em {
        transform: rotate(-180deg);
      }

      .login-menus {
        display: block;
        transform: scale(1);
        opacity: 1;
      }
    }

    .name {
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      align-items: center;

      .level-img {
        width: 52px;
        height: 24px;
        // background-image: url("@/assets/images/mine/02.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        margin-left: 4px;
      }
    }

    .photo {
      width: 48px;
      height: 48px;
      margin-left: 12px;
      border-radius: 100%;
      overflow: hidden;
      background-color: rgba(0, 0, 0, 0.1);
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: #788b9d;
      display: flex;
      align-items: center;
      margin-top: 4px;

      em {
        display: block;
        width: 14px;
        height: 14px;
        margin-left: 4px;
        background: url("@/assets/images/mine/icon79.png") no-repeat center
          center;
        background-size: 100% 100%;
        transition: all 0.4s;
      }
    }
  }
}

.login-menus {
  display: none;
  position: absolute;
  top: 60px;
  right: 0;
  width: 200px;
  background: white;
  border-radius: 6px;
  padding: 12px 24px;
  transition: all 0.4s;
  transform: scale(0.5);
  opacity: 0;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);

  &::before {
    position: absolute;
    content: "";
    top: -8px;
    border: 8px solid transparent;
    border-bottom-color: white;
    border-top: 0;
    right: 20px;
  }

  &::after {
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    height: 22px;
    content: "";
  }

  ul {
    li {
      height: 48px;
      display: flex;
      align-items: center;
      padding: 8px 0;

      img {
        width: 20px;
        height: 20px;
        margin-right: 12px;

        &.xz {
          display: none;
        }
      }

      .text {
        color: #30383f;
      }

      &:hover {
        .xz {
          display: block !important;
        }

        .mr {
          display: none !important;
        }

        .text {
          color: #2599f8;
        }
      }
    }
  }

  .login-out {
    height: 44px;
    outline: none;
    border: 1px solid #e9f5fe;
    font-size: 14px;
    color: #7981a4;
    background-color: #fff;
    padding: 11px 0;
    border-radius: 8px;
    cursor: pointer;
    color: #788b9d;
    text-align: center;
    margin: 8px -12px 0;

    &:hover {
      border: 1px solid #2599f8;
      color: #2599f8;
    }
  }
}

.contact-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-main {
  width: 480px;
  border-radius: 10px;
  overflow: hidden;
  background: white;

  img {
    width: 100%;
  }

  .main {
    padding: 24px;

    h2 {
      text-align: center;
      font-size: 20px;
      font-weight: normal;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 10px;
    }
  }

  .link {
    border-radius: 20px;
    margin: 20px auto 0;
    display: block;
    min-width: 240px;
  }
}

/deep/.lang-style {
  margin-left: 8px;

  .el-input__inner {
    border-radius: 20px !important;
    height: 28px;
    line-height: 28px;
    width: 90px;
    font-size: 14px;
  }

  .el-input__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
